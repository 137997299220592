





































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import Vue from "vue";
import {
  FieldEmail,
  FieldPassword,
  FieldPhone,
  FieldUser,
} from "@/components/account";
import InputCompanies from "@/components/InputCompanies.vue";
import AllowIf from "@/components/AllowIf";
// import BtnCreateOcupation from "@/views/Settings/Nomenclators/BtnCreateOcupation.vue";
import FieldOcupations from "@/components/account/FieldOcupations.vue";
import store from "@/store/index";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { MUT_NOTIFICATION } from "@/store/mutations/types";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError, AxiosResponse } from "axios";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  name: "btn-create-employee",
  components: {
    FieldEmail,
    FieldPassword,
    //FieldPhone,
    FieldUser,
    InputCompanies,
    AllowIf,
    FieldOcupations,
    // BtnCreateOcupation,
  },
  data() {
    return {
      dialog: false,
      passwordRetry: "",
      formValid: false,
      rules: {
        ...rules,
        maxCaracters: (v: string) => v.length >= 3 || this.$t("min3Chars"),
        maxCaractersfull: (v: string) => v.length >= 5 || this.$t("min5Chars"),
      },
      ocupattions: [],
      loadingOcupations: false,

      form: {
        company: "",
        email: "",
        password: "",
        username: "",
        fullname: "",
        phone: "",
        rol: "WORKER",
        ocupationId: 0,
        payXhour: 0,
        hoursToWork: 0,
        origPhone: "",
        ext: "",
      },
    };
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["phonesFree"]),
    ...mapState("crmEmployeeModule", ["loading"]),
    ...mapState("crmCompaniesModule", ["companies"]),
    ...mapGetters({ methodCall: "getMethodCalls" }),
    ...mapGetters(["isSuper", "isAdmin", "isWorker", "isDoctor"]),
    passwordIsRight(): boolean {
      const { passwordRetry, form } = this;
      const same = passwordRetry == form.password;
      const notEmpty = passwordRetry.trim() != "" && form.password.trim() != "";
      // const maxLength = passwordRetry.length >= 8 && form.password.length >= 8;

      return same && notEmpty;
    },
    samePassword(): boolean {
      return this.passwordRetry.trim() == this.form.password.trim();
    },
    enableCreate(): boolean {
      return this.passwordIsRight && this.formValid;
    },
    showForm(): boolean {
      return (this.companies.length > 0 && this.isSuper) || this.isAdmin;
    },
  },
  async mounted() {
    if (store.getters.isSuper) {
      this.init();
    }
    await this.getNumbers();
  },
  methods: {
    ...mapMutations([MUT_NOTIFICATION]),
    ...mapMutations("crmEmployeeModule", ["mutLoading"]),
    ...mapActions("crmCompaniesModule", ["actGetCompanies"]),
    ...mapActions("crmEmployeeModule", ["actCreateEmployee"]),
    ...mapActions("crmConfigurationsModule", [
      "actGetNumbers",
      "actAssignNumber",
    ]),
    async getNumbers() {
      await this.actGetNumbers();
    },

    create() {
      let numberTemp = "";
      let body: any = {};
      const { form } = this;

      if (this.isSuper) {
        body = form;
      }

      if (this.isAdmin) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { company, ...rest } = form;

        body = {
          ...rest,
        };
      }
      if (body.phone != "" && body.phone != undefined) {
        numberTemp = body.phone;
        //body.phone = "+1" + body.phone;
      }
      if (body.payXhour != 0) {
        body.payXhour = Number(body.payXhour);
      }

      if (body.hoursToWork != 0) {
        body.hoursToWork = Number(body.hoursToWork);
      }
      if (body.origPhone != "") {
        if (!body.origPhone.includes("+1")) {
          body.origPhone = "+1" + body.origPhone;
        }
      }

      this.actCreateEmployee(body)
        .then(() => {
          notifySuccess("Employee create succes successfully");
          this.dialog = false;
        })
        .catch((error: AxiosError) => {
          body.phone = numberTemp;
          const errorResponse = error.response?.status;
          let message =
            error.response?.data.message || error.response?.data.details;

          message = error.response?.data.response.message
            ? message + ", " + error.response?.data.response.message[0]
            : message;

          if (errorResponse == ResponseStatus.BAD_REQUEST) {
            const messages =
              message instanceof Array
                ? (message as string[]).reduce(
                    (fullMessage, message) => fullMessage + "\n" + message
                  )
                : message;

            notifyError(messages);
          }

          if (errorResponse == ResponseStatus.CONFLICT) {
            notifyError(`The username <b>${this.form.username}</b> is in use`);
          }

          this.mutLoading(false);
        });
    },
    resetForm(): void {
      this.passwordRetry = "";

      this.form = {
        company: "",
        email: "",
        password: "",
        username: "",
        fullname: "",
        phone: "",
        rol: "WORKER",
        ocupationId: 0,
        payXhour: 0,
        hoursToWork: 0,
        origPhone: "",
        ext: "",
      };

      if (this.$refs.form) {
        (this as any).$refs.form.resetValidation();
      }
    },
    init() {
      this.actGetCompanies();
    },
  },
});

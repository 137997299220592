















import BtnRequestWorkers from "@/views/Employees/buttons/BtnRequestEmployees.vue";
import Vue from "vue";
import AllowIf from "@/components/AllowIf";
import BtnCreateEmployee from "../buttons/BtnCreateEmployee.vue";

export default Vue.extend({
  name: "crm-secondary-toolbar",
  components: {
    BtnRequestWorkers,
    AllowIf,
    BtnCreateEmployee,
  },
});









/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "btn-request-workers",
  computed: {
    ...mapState("crmEmployeeModule", ["loading", "reqrange"]),
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapMutations("crmEmployeeModule", ["mutLoading"]),
    requestWorkers(): void {
      this.actGetEmployees(this.reqrange);
    },
  },
});
